import React from 'react'
import './Timeline.css'

function Timeline() {
  return (
    <div className='timeline'>
        <div className='timeline__posts'>
            <div className='timeline__stats timeline__stats_1'>Intern</div>    
            <div className='timeline__stats timeline__stats_2'>Project Trainee</div>
            <div className='timeline__stats timeline__stats_3'>Member Technical Staff</div>
        </div>
        <div className='timeline__years'>
            <div className='timeline__year timeline__year1'>Oct - 2018</div>    
            <div className='timeline__year timeline__year2'>Jan - 2019</div>
            <div className='timeline__year timeline__year3'>May - 2019</div>
            <div>Present</div>
        </div> 

    </div>

  )
}

export default Timeline