import React from 'react'
import './Work.css'
import {useState} from 'react'
import Timeline from './Timeline';

import UploadDemo from './upload.mp4'
import RecordingsDemo from './recordings.mp4'
import ViewsDemo from './views.mp4'
import ClipboardDemo from './clipboard.mp4'
import DndDemo from './dndupload.mp4'
import ai1 from './ai1.png';
import ai2 from './ai2.png';
import UploadStats from './upload_stats.png'
import RecordingsStats from './recordings_stats.png'
import ClipboardStats from './clipboard_stats.png'
import DndStats from './dndupload_stats.png'

import {useEffect, useRef} from 'react';

function Work() {

    const features = [
        {
            id: 1,
            name: 'Upload',
            desc: "Developed the most-used feature of the product, Upload. Developed and maintained its code base with high readability and reusability. Optimised the feature for extensive usage. (Approximately 2,000,000 uploads a day) This improved the stability of the product, reduced customer complaints and increased the product's overall revenue.",
            demo: [{url: UploadDemo, isVid: true}, {url: UploadStats, isVid: false}],
            link: 'https://help.zoho.com/portal/en/kb/workdrive/teams/my-folders/articles/add-files-folders-to-my-folders'
        },
        {
            id: 2,
            name: 'Recordings',
            desc: "Accomplished a brand new milestone feature: Record Screen, Video & Audio and upload directly into WorkDrive using JS Media Capture and Streams API. This got huge positive response from customers and highly contributes in bringing more resources into the product. (Approximately 1,000 recordings per day) ",
            demo: [{url: "https://www.youtube.com/embed/5HZXAl2VBFc?si=4jL-JhkCjqfRJUn5", isIframe: true}, {url: RecordingsDemo, isVid: true}, {url: RecordingsStats, isVid: false}],
            link: 'https://help.zoho.com/portal/en/kb/workdrive/using-workdrive/articles/recording-options-in-workdrive#Chrome'
        },
        {
            id: 3,
            name: 'Drag and Drop Upload',
            desc: "Introduced Drag and Drop files from finder/file explorer to upload into WorkDrive using HTML Drag and Drop interfaces. This significantly increased overall product usage.(Approximately 100,000 files per day.) ",
            demo: [{url: DndDemo, isVid: true}, {url: DndStats, isVid: false}],
            link: ''
        },
        {
            id: 4,
            name: 'Clipboard Operations',
            desc: "Enhanced the user experience with the introduction of clipboard operations such as move/copy using keyboard shortcuts. (Cmd + C, Cmd + X, and Cmd + V). This improved the overall accessibility of the major features of the product. Implemented an other clipboard operation i.e., pasting a copied file from finder/file explorer or internet to upload directly into WorkDrive. This helped the users bring in more files from various sources. (Around 10,000 uploads per day.)",
            demo: [{url: ClipboardDemo, isVid: true}, {url: ClipboardStats, isVid: false}],
            link: 'https://help.zoho.com/portal/en/kb/workdrive/using-workdrive/articles/add-files-using-keyboard-shortcuts'
        },
        {
            id: 5,
            name: 'AI Integrations',
            desc: "Integrated ChatGPT and DALL-E AI's into the product for content and image generation.",
            demo: [{url: ai1, isVid: false}, {url: ai2, isVid: false}],
            link: ''
        },
        {
            id: 6,
            name: 'Multiple Views',
            desc: "Created multiple views like, List, Compact & Grid to populate the resources. This improved the overall quality of the product.",
            demo: [{url: ViewsDemo, isVid: true}],
            link: ''
        }
    ];

    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const workListDivRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('work__list__animate');
          }
        });
      });
  
      if (workListDivRef.current) {
        observer.observe(workListDivRef.current);
      }
  
      return () => {
        if (workListDivRef.current) {
          observer.unobserve(workListDivRef.current);
        }
      };
    }, []);

  return (
    <div class="work">
        <h1 className='work__header'>Work experience in Zoho WorkDrive</h1>
        <div className="work__container">
            <div className='work__experience__container'>
                <div className='work__experience'>
                    <div className='work__exp__heading'>
                        Key Contribution:
                    </div>
                    <div className='work__exp__content'>
                        <ul className='work__li'>
                            <li>Achieved milestones like WorkDrive Recordings, generated 10,000 hits per day.</li>
                            <li>Enhanced user experience by introducing clipboard operations to increase accessibility of key product features, attracted about 100,000 hits per day.</li>
                            <li>Enabled Service Worker to cache product assets, reduced page load times from 20+ seconds to 6 seconds.</li>
                            <li>Utilized IndexedDB for local data storage, which improved offline loading capabilities.</li>
                            <li>Integrated AI technologies like ChatGPT and DALL-E into the content and image generation product.</li>
                            <li>Transitioned JavaScript components into more reliable TypeScript components.</li>
                            <li>Introduced a drag-and-drop upload feature into WorkDrive, supported the upload of roughly 100,000 files daily.</li>
                        </ul>
                    </div>
                </div>

                <div className='work__experience__dark'>
                    <div className='work__exp__heading'>
                    Key Deliverables:
                    </div>
                    <div className='work__exp__content'>
                    <ul className='work__li'>
                        <li>Contributing as a Technical Staff Member for a cloud-based online file management web application. </li>
                        <li>Involved in cleaning up and optimizing existing features to increase readability, reusability, and ease of integration with other Zoho products.</li>
                        <li>Improving product accessibility in line with ARIA regulations.</li>
                        <li>Leading the implementation of a prototype for the feature release announcement system.</li>
                        <li>Integrating features such as the Upload & Info panel with multiple Zoho products.</li>
                        <li>Upgrading the product's technology stack by transitioning to higher versions of the Ember JS framework.</li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className='work__features'>
            <div ref={workListDivRef} className='work__list'>
                <div className='work__exp__features__heading'>
                        Implemented Features:
                </div>
                {features.map((feature) => (
                    <span key={feature.id} onClick={e => setSelectedFeature(feature)} className={feature.id === selectedFeature.id ? 'work__feature work__feature__active' : 'work__feature'}>{feature.name}</span>
                ))}
            </div>
            <div className='work__right'>
                <h2 className='work__feature__name'>
                    {selectedFeature.name}
                </h2>
                <div className='work__feature__desc'>
                    {selectedFeature.desc}
                    {selectedFeature.link && <a href={selectedFeature.link} target='_blank'> Click here to know more</a>}
                </div>

                <div className='work__demo__container'>
                    {selectedFeature?.demo.map((obj) => (
                        obj.isVid ? 
                            <video src={obj.url} autoplay="true" muted="true" loop="true" playsinline className='work__demo__video'/> :
                            obj.isIframe ? 
                            <iframe width="560" height="315" src={obj.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>:
                            <img src={obj.url} alt='' className='work__demo__image'/>
                    ))}
                </div>
            </div>
        </div>
        <div className='work__timeline'>
                <Timeline />
        </div>
    </div>
  )
}

export default Work