import React from 'react'
import './About.css'
import {Link} from 'react-router-dom';
import {useEffect, useRef} from 'react'
import kctlogo from './kct.jpeg';
import zohologo from './zoho.png';
function About() {

    const aboutDivRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('about__skills__animate');
          } 
        });
      });
  
      if (aboutDivRef.current) {
        observer.observe(aboutDivRef.current);
      }
  
      return () => {
        if (aboutDivRef.current) {
          observer.unobserve(aboutDivRef.current);
        }
      };
    }, []);
  
  return (
    <div id="about" className='about'>
        <div className='about__how'>
            <div className='about__header'>Career Summary</div>
            <span>As a seasoned Senior Front-End Web Developer, I have spent the last 5 years perfecting my skills in JavaScript, HTML, CSS, Handlebars, Ember, and React frameworks. In my current role at Zoho WorkDrive, I've had the privilege of contributing to high-impact projects, developing the most-used feature supporting approximately 200,000 uploads daily and significantly enhancing the product's user experience.</span><br></br>
            <span>My dedication to creating intuitive and powerful interfaces has led to the successful integration of innovative technologies such as ChatGPT and DALL-E AI into our content and image generation product. I have also demonstrated my commitment to quality by transitioning JavaScript components into more reliable TypeScript components. This commitment extends to ensuring excellent user experience and interface, efficient manual testing and debugging, and meticulous document management.</span><br></br>
            <span>As a collaborative team player, I strive to foster an environment that encourages innovation and promotes a customer-centric approach. I am enthusiastic about new challenges and continually seek opportunities to grow both personally and professionally.</span><br></br>
        </div>
        <div class="about__container">
            <div ref={aboutDivRef} className='about__skills'>
                <div className='about__topic'>
                    Key Skills
                </div>
                <div className='about__list'>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>Javascript</li>
                    <li>Typescript</li>
                    <li>Handlebars</li>
                    <li>Ember JS</li>
                    <li>React</li>
                    <li>Bootstrap</li>
                    <li>Service Worker</li>
                    <li>Indexed DB</li>
                    <li>SQLite</li>
                    <li>JQuery</li>
                    <li>JSP</li>
                    <li>Figma</li>
                    <li>Firebase</li>
                    <li>Next JS (Currently Learning)</li>
                    <li>Server Side Rendering</li>
                </div>
            </div>
            <div className='about__items'>
                <div className='about__item'>
                        <div className='about__content'>
                            <span className='about__header'>Work Experience</span>
                            <span className='about__title'>5 years experience as<br></br>Front end developer in Zoho Workdrive</span>
                            <span className='about__desc'>Zoho WorkDrive, a cloud-based file storage and collaboration platform provided by Zoho Corporation. It is designed to help teams and organizations store, share, and collaborate on files and documents in a secure and organized manner.</span>
                            <br></br><span>Over the past 5 years,as a front-end developer, I have gained extensive experience and expertise at Technical Proficiency, Responsive Web Design, Cross-Browser Compatibility, Performance Optimization, Front-End Frameworks & Libraries, Collaboration & Communication, Continuous Learning, Problem Solving & Debugging, User-Centric Design and Quality Assurance & Testing.</span>
                            <br></br><span className='about__desc'><Link to='/work'>Click here</Link> to know more about my Work Experience</span>
                        </div>
                        <div className='about__img'>
                            <a href="https://www.zoho.com/workdrive/" target="_blank"><img src={zohologo} alt="zoho_workdrive_logo"/></a>
                        </div>
                </div>
                <div className='about__item'>
                        <div className='about__content'>
                            <span className='about__header'>Personal Projects</span>
                            <span className='about__desc'>I am a passionate and dedicated learner, constantly expanding my knowledge and skills. Beyond my professional work, I actively explore new technologies and integrate them into personal projects. Curiosity and a drive for growth are at the core of my learning journey.</span>
                            <br></br><span className='about__desc'><Link to='/projects'>Click here</Link> to discover more about my ongoing pursuit of knowledge and skill development.</span>
                        </div>
                </div>
                <div className='about__item'>
                        <div className='about__content'>
                            <span className='about__header'>Education</span>
                            <span className='about__title'>Bachelor of Engineering - 8.0 CGPA</span>
                            <span className='about__desc'>Electrical and Electronics</span>
                            <a>Kumaraguru College of Engineering, Coimbatore, Tamil Nadu, India.</a>
                        </div>
                        <div className='about__img'>
                            <a href="https://www.kct.ac.in/" target="_blank"><img src={kctlogo} alt="kct_logo"/></a>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About