import React from 'react'
import './Footer.css';
import indiaflag from './india-flag-round-circle-icon.png'
function Footer() {
  return (
    <div className='footer'>
        <div className='footer__greetings'>"Collaboration starts with a conversation, Contact me."</div>
        <div className='footer__container'>
            <div className='footer__left'>
                <span className='footer__heading'>Mobile</span>
                <span className='footer__sub'>+917868814264</span>
                <span className='footer__heading'>E-Mail</span>
                <span className='footer__sub'>garunk09@gmail.com</span>
            </div>
            <div className='footer__right'>
                <span className='footer__heading'>Residential Address</span>
                <span className='footer__sub'>E-104, Superb by Urban Tree, Iyenchery, Urapakkam, Chennai, Tamil Nadu, India - 603210</span> 
                <span className='footer__heading'>Permanent Address</span>
                <span className='footer__sub'>35, Ilaignar Sanga 1st Street, Vellaikottai, Aruppukottai, Virudhunagar, Tamil Nadu, India - 626101</span>
            </div>
            <img className='footer_qr' src="https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fwww.linkedin.com%2Fin%2Farun-kumar-gopalakrishnan-b9515a15a%2F&chs=180x180&choe=UTF-8&chld=L|2" alt="qr code"/>
        </div>
        <div className='footer__bottom'>
            <div className='footer__owner'>© 2023 Created by AK </div>
            <img className="footer__flag" src={indiaflag}/>

        </div>
    </div>
  )
}

export default Footer