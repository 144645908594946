import React from 'react';
import './Projects.css';
import {useState} from 'react';
import ecommerce from './ecommerce.png';
import netflix from './netflix.png';
import c19 from './c19.png';
import GitHubIcon from '@mui/icons-material/GitHub';

function Projects() {

    const projects = [
        {
            id: 1,
            name: 'E-Commerce website',
            desc: "Interactive Home page with a static header having sing-in/sign-out, go to orders, go to basket options and a listing of products with add to basket, show product details actions etc. Detailed orders/payments page and basket page. A dynamic callout while adding products to basket and a responsive product details dialog while clicking product's image. Used Stripe for payments. Used Firebase for hosting and storing user's login and basket details.",
            demo: ecommerce,
            link: 'https://by-ak-773c0.web.app/',
            git: 'https://github.com/ArunkumarGopalakrishnan1998/ecommerce-website'
        },
        {
            id: 2,
            name: 'Netflix home page',
            class: "projects__project__dark",
            desc: "An interactive netflix home page with the help of TMDB APIs(The Movie DataBase).",
            demo: netflix,
            link: 'https://netflix-by-ak.web.app/',
            git: 'https://github.com/ArunkumarGopalakrishnan1998/netflix-clone'
        },
        {
            id: 3,
            name: 'Covid-19 Tracker',
            desc: "A live COVID-19 cases tracker with the help of disease.sh APIs.",
            demo: c19,
            link: 'https://c19-tracker-ak.web.app/',
            git: 'https://github.com/ArunkumarGopalakrishnan1998/Covid-19-tracker'
        }
    ];

  return (
    <div className='projects'>
        <h1 className='projects__header'>My Personal Projects</h1>
        <div className='projects__container'>
            {projects.map((project) => (
                <>
                    <div className={project.class ? `${project.class} projects__project` : 'projects__project'}>
                        <div className='project__details'>
                            <div className='project__name'>
                                {project.name}
                            </div>
                            <div className='project__desc'>
                                {project.desc}
                            </div>
                            <div className='project__gitbub'>
                                <a href={project.git} target="_blank" rel="noopener noreferrer" className="project__gitbub__icon"><GitHubIcon fontSize="large"/></a>
                            </div>
                        </div>
                        <div className='project__img__container'>
                            <img className='project__img' src={project.demo} alt=""/>
                            <a href={project.link} target="_blank" className='project__img__overlay'><h2>Go to the Project</h2></a>
                        </div>
                    </div>
                </>
            ))}
        </div>
    </div>
  )
}

export default Projects