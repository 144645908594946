import './App.css';
import { Routes, Route } from 'react-router-dom';

import Summary from './Summary.js';
import Header from './Header.js';
import About from './About.js';
import Footer from './Footer.js';
import Work from './Work.js';
import Projects from './Projects.js';
import ScrollTop from './ScrollTop';

function App() {
  return (
    <div className="app">

      <Header />

        <Routes>

          <Route exact path="/" element={
            <>
              <ScrollTop />
              <Summary />
              <About />
            </>
          } />

          <Route path="/work" element={
            <>
              <ScrollTop />
              <Work />
            </>
          } />

          <Route path="/projects" element={
            <>
              <ScrollTop />
              <Projects />
            </>
          } />

        </Routes>

      <Footer />

    </div>
  );
}

export default App;
