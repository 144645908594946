import React from 'react'
import './Summary.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import {Link} from 'react-router-dom';
import ProfilePic from './Arun.png';
import Pic from './File_000.jpeg';

import Header from './Header';
function Summary() {

    const goToAbout = () => {
        document.getElementById('about').scrollIntoView({behavior: "smooth"});
    }
  return (
    <div className='summary'>
        <div className='summary_container'>
            <div className='summary__text'>
                <div className='summary__header'>
                    <span className='summary__title'>Hello! I'm Arun</span>
                    <span className='summary__sub'>(Frontend Developer)</span>
                </div>
                <div className='summary__social'>
                    <a href='https://www.linkedin.com/in/arun-kumar-gopalakrishnan-b9515a15a/' target="_blank" rel="noopener noreferrer" className="summary__item"><LinkedInIcon fontSize="large"/></a>
                    <a href='https://github.com/ArunkumarGopalakrishnan1998?tab=repositories' target="_blank" rel="noopener noreferrer" className="summary__item"><GitHubIcon fontSize="large"/></a>
                </div>
                <div className='summary__buttons'>
                    <button className='summary__aboutme' onClick={goToAbout}>More About Me</button>
                    <button className='summary__aboutme summary__cv' onClick={e => window.open('https://drive.google.com/file/d/1U_sW89TVPkWtXXekbsxg4T2Um88q48c2/view?usp=sharing', '_blank' )}>Get CV</button>
                </div>
            </div>
            <img className='summary__pic' src={Pic} alt=""></img>
        </div>
    </div>
  )
}

export default Summary