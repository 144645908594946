import React from 'react';
import './Header.css';
import aklogo from './ak.png';
import {Link} from 'react-router-dom';

function Header() {
  return (
    <div className='header'>
        <div className='header__left'>
            <Link to='/' className="header__item"><h4>Arun Kumar Gopalakrishnan</h4></Link>
        </div>
        <div className='header__contents'>
            <Link to='/' className="header__item"><span>Home</span></Link>
            <Link to='/work' className='header__item'><span>Work</span></Link>
            <Link to='/projects' className='header__item'><span>Projects</span></Link>
        </div>
    </div>
  )
}

export default Header